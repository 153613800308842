import Vue from 'vue';
import Vuex from 'vuex';
import jumble from './modules/jumble';
import sesh from './modules/sesh';
import session from './modules/session';
import modal from './modules/modal';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    jumble,
    session,
    sesh,
    modal
  },
});
