<template>
  <div class="text-center ma-2">
    <v-snackbar v-model="sesh.snack.display" absolute centered top :timeout="3000" :color="sesh.snack.color">
      {{ sesh.snack.text }}
    </v-snackbar>
  </div>
</template>

<script>
  import {
    mapState
  } from 'vuex';

  export default {
    computed: {
      ...mapState(['sesh']),
    },
    data() {
      return {
        timeout: 2000
      }
    },
  };
</script>