// import Vue from "vue";

import _ from 'lodash'
import Vue from "vue";

function setSesh(key, data) {
  // Vue.prototype.$session.set(key, data)
  localStorage.setItem(key, JSON.stringify(data));
}

function getSesh(key) {
  // return Vue.prototype.$session.get(key)
  return JSON.parse(localStorage.getItem(key));
}

function hasSesh(key) {
  // return (Vue.prototype.$session.has(key) === null ? false : true)
  return (localStorage.getItem(key) === null ? false : true)
}

const sesh = {
  namespaced: true,
  // Initial state
  state: {
    session: {},
    snack: {
      display: false,
      text: ''
    },
    progress: {
      message: ""
    },
    isTimerRunning: true
  },
  // Mutations
  mutations: {
    SET_STATE(state, data) {
      state.session = data
      setSesh(state.session.id, data)
    },
    SET_GUESS(state, data) {
      state.session.guess = data;
      if (!state.session.guess) {
        state.session.buttonPressed = [];
      }
      console.info(`data: ${data}`);
      state.isTimerRunning = true
      setSesh(state.session.id, state.session)
    },
    ADD_DEFINITION(state, data) {
      state.session.definitions.push(data)
      setSesh(state.session.id, state.session)
    },
    ADD_CORRECT(state, data) {
      // Clear guess
      state.session.guess = '';
      state.session.buttonPressed = [];
      // Add to correct guesses
      state.session.correct.push(data)
      // Save session
      setSesh(state.session.id, state.session)
    },
    ADD_INCORRECT(state, data) {
      // Clear guess
      state.session.guess = '';
      state.session.buttonPressed = [];
      // Add to incorrect guesses
      state.session.incorrect.push(data)
      // Save session
      setSesh(state.session.id, state.session)
    },
    UPDATE_PROGRESS(state) {
      var minutes = parseInt((state.session.seconds) / 60)
      state.session.cgpm = (minutes ? parseFloat(state.session.correct.length / minutes).toFixed(1) : parseFloat(state.session.correct.length).toFixed(1))
    },
    TOGGLE_TIMER(state) {
      state.isTimerRunning = !state.isTimerRunning
    },
    UPDATE_TIMER(state) {
      if (state.isTimerRunning) {
        state.session.seconds = state.session.seconds + 0.1
        var duration = state.session.seconds * 1000
        // var duration = Math.abs(new Date() - new Date(state.session.startedAt))
        if (duration) {
          const diffInHrs = duration / 3600000;
          const hh = Math.floor(diffInHrs);

          const diffInMin = (diffInHrs - hh) * 60;
          const mm = Math.floor(diffInMin);

          const diffInSec = (diffInMin - mm) * 60;
          const ss = Math.floor(diffInSec);

          const formattedMM = mm.toString().padStart(2, '0');
          const formattedSS = ss.toString().padStart(2, '0');

          let formattedHH = '';
          if (hh > 0) {
            formattedHH = hh.toString().padStart(2, '0');
          }

          state.session.timer = `${formattedHH ? `${formattedHH} h` : ''} ${formattedMM} min ${formattedSS} s`;
        }
        setSesh(state.session.id, state.session)
      }
    },
    CLEAR_SESH(state, data) {
      var session = {
        id: data,
        guess: '',
        correct: [],
        incorrect: [],
        definitions: [],
        startedAt: new Date(),
        seconds: 0,
        timer: '00 min 00 s',
        cgpm: 0.0,
        buttonPressed: []
      }
      console.log(state.session.id, session)
      setSesh(state.session.id, session)
    },
    SET_SNACK(state, data) {
      state.snack.display = true
      state.snack.text = data.msg
      state.snack.color = data.color || 'green'
    },
    SET_POPUP(state, data) {
      state.progress.message = data.message
    },
    DROP_LETTER(state) {
      state.session.guess = state.session.guess.substr(0, state.session.guess.length - 1);
      state.session.buttonPressed.pop();
    }
  },
  getters: {

  },
  // Actions
  actions: {
    async fetch({
      commit
    }, data) {
      var session
      if (hasSesh(data)) {
        session = getSesh(data)
      } else {
        session = {
          id: data,
          guess: '',
          correct: [],
          incorrect: [],
          definitions: [],
          startedAt: new Date(),
          seconds: 0,
          timer: '00 min 00 s',
          cgpm: 0.0,
          letters: [],
          buttonPressed: []
        }
      }
      commit('SET_STATE', session);
    },
    async updateGuess({
      commit
    }, data) {
      await commit('SET_GUESS', data);
    },
    clearGuess({
      commit
    }) {
      commit('SET_GUESS', '')
    },
    dropLetter({
      commit
    }) {
      commit('DROP_LETTER')
    },
    async clear({
      commit, state
    }, data) {
      if(state.isTimerRunning) {
        await commit('TOGGLE_TIMER')
      }
      await commit('CLEAR_SESH', data)
      await commit('UPDATE_PROGRESS')
      await commit('UPDATE_TIMER')
    },
    setSnack({
      commit
    }, data) {
      commit('SET_SNACK', data)
    },
    setPopup({
      commit
    }, data) {
      commit('SET_POPUP', data)
    },
    async addCorrectGuess({
      commit
    }, data) {
      var response = await Vue.prototype.$axios.get(`definitions/${data}`)
      var definition = {}
      definition[data] = response.data.definition
      commit('ADD_DEFINITION', definition)
      commit('ADD_CORRECT', data)
      var snackData = {
        msg: response.data.definition,
        color: 'green'
      }
      commit('SET_SNACK', snackData)
    },
    addIncorrectGuess({
      commit
    }, data) {
      commit('ADD_INCORRECT', data);
      var snackData = {
        msg: 'Incorrect guess.',
        color: 'orange'
      };
      commit('SET_SNACK', snackData);
    },
    updateProgress({
      commit
    }) {
      commit('UPDATE_PROGRESS')
    },
    updateTimer({
      commit
    }) {
      commit('UPDATE_TIMER')
    },
    toggleTimer({
      commit
    }) {
      commit('TOGGLE_TIMER')
    },
    showDefinition({
      commit,
      state
    }, data) {
      var definition = _.find(state.session.definitions, data)[data]
      var snackData = {
        msg: definition,
        color: 'green lighten-2'
      }
      commit('SET_SNACK', snackData)
    }
  },
};

export default sesh;