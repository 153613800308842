const modal = {
  namespaced: true,
  // Initial state
  state: {
    display: false,
    title: "",
    message: null,
    solution: [],
    color: "",
    finished: false
  },
  // Mutations
  mutations: {
    SET_MODAL(state, data) {
      state.title = data.title
      state.message = data.message
      state.solution = data.solution
      state.color = data.color
      state.display = data.display
    },
    CLEAR_MODAL(state) {
      state.display = false
    },
  },
  // Actions
  actions: {
    clearModal({
      commit
    }) {
      commit('CLEAR_MODAL')
    },
    setModal({
      commit
    }, data) {
      commit('SET_MODAL', data)
    },
  },
};

export default modal;