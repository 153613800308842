<template>
  <div id="toolbar">
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn id="butShuffle" class="mx-2" fab dark small v-bind="attrs" v-on="on" color="success" @click="shuffle">
          <v-icon dark>fa-sync-alt</v-icon>
        </v-btn>
      </template>
      <span>Shuffle Letters</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn id="butBackspace" class="mx-2" fab dark small v-bind="attrs" v-on="on" color="info" @click="backspace">
          <v-icon dark>fa-arrow-alt-left</v-icon>
        </v-btn>
      </template>
      <span>Backspace</span>
    </v-tooltip>

    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-btn id="butClear" class="mx-2" fab dark small v-bind="attrs" v-on="on" color="error" @click="clear">
          <v-icon dark>fa-ban</v-icon>
        </v-btn>
      </template>
      <span>Clear Input</span>
    </v-tooltip>
  </div>
</template>

<style scoped>
  #toolbar {
    margin-bottom: 30px;
  }
</style>

<script>
  import {
    mapState,
    mapGetters
  } from 'vuex';

  export default {
    data() {
      return {
      };
    },
    computed: {
      ...mapState(['sesh']),
      ...mapGetters('jumble', ['getLetters']),
    },
    methods: {
      shuffle() {
        this.$store.dispatch('jumble/shuffle')
      },
      backspace() {
        this.$store.dispatch('sesh/dropLetter')
      },
      clear() {
        this.$store.dispatch('sesh/clearGuess');
      }
    },
  };
</script>