import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,[_c(VCol,[_c(VList,{attrs:{"dense":"","rounded":""}},[_c(VSubheader,[_vm._v("Correct")]),_c(VChipGroup,{attrs:{"column":""}},_vm._l((_vm.correctAlphabetised),function(answer){return _c(VChip,{key:answer,staticClass:"green--text text--darken-2",on:{"click":function($event){return _vm.define(answer)}}},[_vm._v(" "+_vm._s(answer)+" ")])}),1)],1)],1)],1),_c(VRow,[_c(VCol,[_c(VList,{attrs:{"dense":""}},[_c(VSubheader,[_vm._v("Incorrect")]),_c(VChipGroup,{attrs:{"column":""}},_vm._l((_vm.sesh.session.incorrect),function(answer){return _c(VChip,{key:answer,staticClass:"orange--text text--darken-3"},[_vm._v(" "+_vm._s(answer)+" ")])}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }