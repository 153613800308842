import colors from 'vuetify/lib/util/colors';

export default {
  theme: {
    themes: {
      light: {
        primary: '#043de2',
        secondary: colors.grey.darken1,
        accent: colors.purple.darken1,
        success: colors.green.darken3,
        info: colors.blue.darken3,
        error: colors.red.darken3,
      },
    },
  },
};
