<template>
  <div class="home">
    <h1>Welcome to Jumble / Target Time by Auspac Media</h1>
    <h3>This is a holding page only.</h3>
    <p>Please request a link from Auspac Media to access the game.</p>
  </div>
</template>

<script>
export default {
  name: 'Home',
};
</script>
