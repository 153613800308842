<template>
  <div>
    <input id="guessbox" class="form-control" type="text" placeholder="Enter guess" v-model="sesh.session.guess"
      v-on:keyup.enter="$emit('requestChecking')" v-on:keydown="letterPress">
      
  </div>
</template>

<script>
  import {
    mapState,
    mapGetters
  } from 'vuex';

  

  export default {
    computed: {
      ...mapState(['jumble']),
      ...mapState(['sesh']),
      ...mapGetters('session', ['check']),
      ...mapGetters('jumble', ['isLetterValid'])
    },
    methods: {
      letterPress(event) {
        var isValid
        var letter = this.$_.lowerCase(event.key)

        // List allowed keys.
        var allowed = ['Backspace', 'Delete', 'Control', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight']

        // Check if letter belongs to the game or it is on the list of allowed keys.
        isValid = this.isLetterValid(this.$_.lowerCase(letter)) || allowed.includes(event.key)

        // Prevent if invalid.
        if(!isValid) {
          event.preventDefault()
        }  if (event.key == 'Backspace') {
          this.sesh.session.buttonPressed.pop();
        } else {
          let i = 0;
          for (const l of this.jumble.letters) {
            const buttonId = l.letter + i;
            if (letter == l.letter.toLowerCase() && !this.sesh.session.buttonPressed.includes(buttonId)) {
              this.sesh.session.buttonPressed.push(buttonId);
              break;
            }
            i++;
          }          
        }
      },
    }
  }
</script>