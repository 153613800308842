<template>
  <v-row>
    <v-col>
      <div class="loader text-center">
        <v-progress-circular size="70" width="7" indeterminate color="primary">
        </v-progress-circular>
      </div>
    </v-col>
  </v-row>
</template>

<style scoped>
.loader {
  position: fixed;
  left: 0px;
  top: 150px;
  width: 100%;
  height: 100%;
  z-index: 100;
}
</style>

<script>
export default {
  props: ['condition'],
};
</script>
