import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.display),callback:function ($$v) {_vm.display=$$v},expression:"display"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline grey lighten-2"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardText,[_c('p',[_vm._v(" "+_vm._s(_vm.text)+" ")])]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"success"},on:{"click":function($event){return _vm.$emit('yes')}}},[_vm._v(" Yes ")]),_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){return _vm.$emit('no')}}},[_vm._v(" No ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }