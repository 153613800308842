// Test route: http://localhost:3333/jumblewords/86

import Vue from 'vue';
import _ from 'lodash';

const jumble = {
  namespaced: true,
  // Initial state
  state: {
    jumble: {},
    letters: [],
    listgames: ""
  },
  // Mutations
  mutations: {
    SET(state, data) {
      state.jumble = data;
      state.listgames = `/listgames/${_.kebabCase(data.client_name)}`
    },
    SHUFFLE_LETTERS(state) {
      var letters = _.shuffle(state.jumble.letters);
      var requiredTemp;
      letters = letters.map(letter => {
        if (letter.required) {
          requiredTemp = letter
          return null
        } else {
          return letter
        }
      })
      // Remove null values
      _.pull(letters, null);
      // Iterate through letters and put required at position 4
      var tmpLetters = [];
      _.each(letters, function (letter, key) {
        if (key == 4) {
          tmpLetters.push(requiredTemp);
        }
        tmpLetters.push(letter);
      });

      state.letters = tmpLetters
    }
  },
  // Actions
  actions: {
    async fetch({commit}, idOrClient) {
      let endpoint = `/jumblewords/${idOrClient}`;
      const isClient = isNaN(Number(idOrClient));
      if (isClient) {
        endpoint = `/latest?client=${idOrClient}&game=jumbleword`
      }
      const response = await Vue.prototype.$axios.get(endpoint);
      let { data } = response;
      if (isClient) {
        data = data.map(d => {
          const needParsing = ['answers', 'letters', 'scores', 'options'];
          for (const np of needParsing) {
            d[np] = JSON.parse(d[np]);
          }
          return {...d};
        });
        data = {...data[0]};
      }
      commit('SET', data);
      commit("SHUFFLE_LETTERS")
    },
    shuffle({commit}) {
      commit("SHUFFLE_LETTERS")
    }
  },
  // Getters
  getters: {
    check:(state) => (data) => {
      // Set guess to lowercase
      var guess = _.lowerCase(data)
      // If the guess is in the answers then add to the session as correct.'
      return state.jumble.answers.includes(guess)
    },
    isLetterValid: (state) => (letter) => {
      var letters = state.jumble.letters.map((letter) => {
        return _.lowerCase(letter.letter)
      })
      return letters.includes(letter)
    },
    getJumbledWord: (state) => () => {
      var letters = _.shuffle(state.jumble.letters).map((letter) => {
        return letter.letter
      });
      var word = letters.join("")
      return _.upperCase(word)
    },
    getLetters: (state) => () => {
      var letters = _.shuffle(state.jumble.letters);
      var requiredTemp;
      letters = letters.map(letter => {
        if (letter.required) {
          requiredTemp = letter
          return null
        } else {
          return letter
        }
      })
      // Remove null values
      _.pull(letters, null);
      // Iterate through letters and put required at position 4
      var tmpLetters = [];
      _.each(letters, function (letter, key) {
        if (key == 4) {
          tmpLetters.push(requiredTemp);
        }
        tmpLetters.push(letter);
      });
      return tmpLetters
    },

  },
};

export default jumble;