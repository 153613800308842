<template>
  <v-main id="gameContainer">
    <v-row>
      <v-col cols="12" md="6">
        <progress-bar></progress-bar>
        <word-input @requestChecking="checkGuess()"></word-input>
        <toolbar></toolbar>
        <keyboard @requestChecking="checkGuess()"></keyboard>
        <clock></clock>
      </v-col>
      <v-col cols="12" md="6">
        <list></list>
      </v-col>
      <snackbar></snackbar>
    </v-row>
    <modal></modal>
  </v-main>
</template>

<script>
  import {
    mapState,
    mapGetters
  } from 'vuex';
  import Toolbar from './Toolbar.vue';
  import Keyboard from './Keyboard.vue';
  import WordInput from './WordInput.vue';
  import ProgressBar from './ProgressBar.vue';
  import List from './List.vue';
  import Clock from './Clock.vue';
  import Snackbar from './Snackbar.vue';
  import Modal from './Modal.vue';

  export default {
    components: {
      Keyboard,
      Toolbar,
      WordInput,
      ProgressBar,
      List,
      Clock,
      Snackbar,
      Modal
    },
    computed: {
      ...mapState('jumble', ['jumble']),
      ...mapState(['sesh']),
      ...mapState(['modal']),
      ...mapGetters('jumble', ['check']),
      url() {
        return `${window.location.origin}/listgames/${this.$_.kebabCase(this.jumble.client_name)}`;
      },
    },
    async mounted() {
      const idOrClient = this.$route.params.id || this.$route.params.client;
      await this.$store.dispatch('jumble/fetch', idOrClient);
      await this.$store.dispatch('sesh/fetch', this.jumble.name);
      this.$session.set(this.jumble.name, {})
    },
    methods: {
      checkGuess() {
        // Set guess to lowercase
        var guess = this.$_.lowerCase(this.sesh.session.guess)
        var snackData
        // Check if the target word was guessed.
        if (guess == this.$_.lowerCase(this.jumble.target)) {
          console.log("You guessed the word!")
          this.$store.dispatch('modal/setModal', {
            display: true,
            title: "Congratulations",
            message: `You guessed the target word, ${this.jumble.target}.`,
            color: 'green'
          });
        }
        if (guess.length > 0) {
          // Debugging
          console.log(`Checking: ${guess}`);
          // Check if already guessed
          var alreadyGuessed = this.sesh.session.correct.includes(guess) || this.sesh.session.incorrect.includes(guess)
          if (alreadyGuessed) {
            // Popup goes here
            snackData = {
              msg: 'Already guessed.',
              color: 'green lighten-2'
            }
            this.$store.dispatch('sesh/setSnack', snackData);
            console.log("Already guessed.");
            // Clear guess
            this.$store.dispatch('sesh/updateGuess', '');
          } else {
            var result = this.check(guess)
            if (result) {
              console.log('Correct, adding to list.')
              this.$store.dispatch('sesh/addCorrectGuess', guess)
              // Check if all words have been guessed (add one to account for the current correct guess not being added to the store yet).
              console.log(this.sesh.session.timer)
              if ((this.sesh.session.correct.length + 1) == this.jumble.answers.length) {
                // Game completed modal.
                console.log("You've completed the puzzle!")
                this.$store.dispatch('modal/setModal', {
                  display: true,
                  title: "Congratulations",
                  message: `You've completed the game and guessed all ${this.jumble.answers.length} words in ${this.sesh.session.timer}.`,
                  color: 'green',
                  finished: true
                });
              }
            } else {
              this.$store.dispatch('sesh/addIncorrectGuess', guess)
              console.log('Error or incorrect guess.')
            }
          }
        } else {
          // Popup goes here
          snackData = {
            msg: 'Input is empty.',
            color: 'red lighten-2'
          }
          this.$store.dispatch('sesh/setSnack', snackData);
          console.log(`Empty!`)
        }
      }
    }
  }
</script>