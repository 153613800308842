<template>
  <div>
    <v-col>
      <!-- Keyboard Input -->
      <template v-for="(letter, i) in jumble.letters">
        <br v-if="(i % 3 == 0) && (i !== 0)" v-bind:key="i">
        <button class="jumble button" outlined v-bind:id="letter.letter + i" v-bind:key="letter.letter + i"
          v-on:click="letterPress($event.target)"
          v-bind:class="{ required: letter.required, pressed: pressed(letter, letter.letter + i) }">{{ letter.letter }}</button>
      </template>
    </v-col>
    <v-col>
      <!-- Check button -->
      <v-btn id="butCheck" class="mx-2" fab dark small color="info"
        @click="$emit('requestChecking')">
        <v-icon dark>fa-check</v-icon>
      </v-btn>
    </v-col>
  </div>
</template>

<script>
  import {
    mapState,
  } from 'vuex';

  export default {
    computed: {
      ...mapState(['sesh']),
      ...mapState(['jumble']),
    },
    methods: {
      pressed(letter, buttonId) {
        const bp = this.sesh.session.buttonPressed;
        return Array.isArray(bp) && (bp.includes(buttonId));
      },
      letterPress(event) {
        var letter = event.innerText;
        var guess = this.sesh.session.guess + letter;
        if (!Array.isArray(this.sesh.session.buttonPressed))
        {
          this.sesh.session.buttonPressed = [];
        }
        this.sesh.session.buttonPressed.push(event.id);
        this.$store.dispatch('sesh/updateGuess', guess);
      },
    }
  };
</script>