<template>
  <div>
    <v-progress-linear v-if="sesh.session.cgpm" class="my-2" :color="color" :value="progress" height="25">
      <template v-slot:default="">
        <strong style="color: white; position: absolute; left: 5px;">{{ progressAlt }}</strong>
        <strong style="position: absolute; right: 5px;">{{ progressMessage }}</strong>
      </template>
    </v-progress-linear>
  </div>
</template>

<script>
  import {
    mapGetters,
    mapState
  } from 'vuex';

  export default {
    computed: {
      ...mapState(['sesh', 'jumble']),
      ...mapGetters('sesh', ['progressMessage']),
      progress() {
        return parseInt((this.sesh.session.correct.length / this.jumble.jumble.answers.length) * 100)
      },
      progressAlt() {
        return `${this.sesh.session.correct.length} / ${this.jumble.jumble.answers.length}`
      },
      progressMessage() {
        var percentage = this.progress
        switch (true) {
          case percentage > 95:
            return "Grand Master!!!"
          case percentage > 70:
            return "Genius!"
          case percentage > 55:
            return "Excellent"
          case percentage > 40:
            return "Very good"
          case percentage > 25:
            return "Good"
          case percentage > 5:
            return "Nice work"
          default:
            return ""
        }
      },
      color() {
        var colors = [
          "#04377A",
          "#5473B0",
          "#6C9FE0",
          "#83C3F2",
          "#99E6FF",
        ]
        var colorID = Math.floor((this.progress / 20))
        return colors[colorID]
      }
    },
    mounted() {
      setInterval(() => {
        this.$store.dispatch('sesh/updateProgress')
      }, 1000)
    }
  }
</script>