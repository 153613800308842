import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.modal.display),callback:function ($$v) {_vm.$set(_vm.modal, "display", $$v)},expression:"modal.display"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline grey lighten-2"},[_vm._v(" "+_vm._s(_vm.modal.title)+" ")]),_c(VCardText,{staticClass:"pt-5"},[(_vm.modal.message)?_c('p',[_vm._v(" "+_vm._s(_vm.modal.message)+" ")]):_c('p',_vm._l((_vm.modal.solution),function(word){return _c('span',{key:word,class:( _vm.sesh.session.correct.includes(word) ? 'font-weight-black' : '')},[_vm._v(" "+_vm._s(word)+" ")])}),0)]),_c(VDivider),_c(VCardActions,[_c(VSpacer),(_vm.modal.finished)?_c('div',[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.resetGame()}}},[_vm._v(" Reset Game ")]),_c(VBtn,{attrs:{"color":"success"},on:{"click":function($event){return _vm.gameList()}}},[_vm._v(" Return to game list ")])],1):_c('div',[_c(VBtn,{attrs:{"color":"success"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }