import axios from 'axios';
import lodash from 'lodash';
import Vue from 'vue';
import Vuex from 'vuex';
import VueSession from 'vue-session';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import App from './App.vue';

Vue.use(VueSession, { persist: true });
Vue.config.productionTip = false;

// Global Axios config
const axiosConfig = {
  baseURL: process.env.VUE_APP_API_URL,
};

const api = axios.create(axiosConfig);

Object.defineProperty(Vue.prototype, '$axios', { value: api });
Object.defineProperty(Vue.prototype, '$_', { value: lodash });

Vue.use(Vuex);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
