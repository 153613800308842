<template>
  <div class="text-center">
    <v-dialog
      v-model="display"
      width="500"
    >

      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ title }}
        </v-card-title>

        <v-card-text>
          <p>
            {{ text }}
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            @click="$emit('yes')"
          >
            Yes
          </v-btn>
          <v-btn
            @click="$emit('no')"
            color="error">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['display', 'text', 'title'],
};
</script>
