import _ from 'lodash';

const session = {
  namespaced: true,
  // Initial state
  state: {
    name: '',
    session: {
      start: null,
      input: [],
      lastCell: null,
      timer: 0,
    },
    timerFormat: '00 min 00 s',
    startTimer: false,
    answers: [],
    guess: ''
  },
  // Mutations
  mutations: {
    SET_NAME(state, data) {
      state.name = data;
    },
    SET_INPUT(state, data) {
      state.session.input = data;
    },
    SET_START(state, data) {
      state.session.start = data;
    },
    SET_SESSION(state, data) {
      state.session = data;
    },
    SET_TIMER(state) {
      const timer = Date.now() - state.session.start;
      state.session.timer = timer;
      localStorage.setItem(state.name, JSON.stringify(state.session));
    },
    CLEAR_TIMER(state) {
      state.session.timer = 0;
    },
    START_TIMER(state) {
      state.startTimer = true;
    },
    PAUSE_TIMER(state) {
      state.startTimer = false;
    },
    SET_CELL(state, data) {
      state.session.lastCell = data;
    },
    STORE_SESSION(state) {
      localStorage.setItem(state.name, JSON.stringify(state.session));
    },
    ADD_INPUT(state, data) {
      // Update if value already exists only otherwise add.
      const alreadyInput = _.find(state.session.input, { id: data.id });
      if (alreadyInput) {
        alreadyInput.value = data.value;
      } else {
        state.session.input.push(data);
      }
      localStorage.setItem(state.name, JSON.stringify(state.session));
    },
    CLEAR_CELL(state) {
      const getInput = _.find(state.session.input, { id: state.session.lastCell });
      getInput.value = '';
      const index = _.findIndex(state.answers, (answer) => answer.id === state.session.lastCell);
      state.answers.splice(index, 1);
    },
    SET_ANSWER(state, data) {
      const res = {
        id: data.id,
        value: data.value,
      };
      const index = _.findIndex(state.answers, (answer) => answer.id === res.id);
      if (index === -1) {
        state.answers.push(res);
      }
    },
    REMOVE_ANSWER(state, data) {
      const index = _.findIndex(state.answers, (answer) => answer.id === data.id);
      if (index !== -1) {
        state.answers.splice(index, 1);
      }
    },
    CLEAR_ANSWER(state, data) {
      state.answers = data;
    },
    SET_TIMEFORMAT(state, data) {
      if (data) {
        const diffInHrs = data / 3600000;
        const hh = Math.floor(diffInHrs);

        const diffInMin = (diffInHrs - hh) * 60;
        const mm = Math.floor(diffInMin);

        const diffInSec = (diffInMin - mm) * 60;
        const ss = Math.floor(diffInSec);

        const formattedMM = mm.toString().padStart(2, '0');
        const formattedSS = ss.toString().padStart(2, '0');

        let formattedHH = '';
        if (hh > 0) {
          formattedHH = hh.toString().padStart(2, '0');
        }

        state.timerFormat = `${formattedHH ? `${formattedHH} h` : ''} ${formattedMM} min ${formattedSS} s`;
      }
    },
  },
  // Actions
  actions: {
    async fetch({ commit, rootState }) {
      const name = await rootState.game.game.name;
      await commit('SET_NAME', name);
      // Look for previously stored game data or create.
      const localData = await JSON.parse(localStorage.getItem(name));
      if (_.isEmpty(localData)) {
        const clearInput = [];
        await commit('SET_INPUT', clearInput);
        await commit('STORE_SESSION');
      } else {
        await commit('SET_SESSION', localData);
        await commit('SET_TIMEFORMAT', localData.timer);
      }
    },
    async addInput({ commit }, data) {
      await commit('ADD_INPUT', data);
    },
    async setCell({ commit }, data) {
      await commit('SET_CELL', data);
      await commit('STORE_SESSION');
    },
    async clearCell({ commit }) {
      await commit('CLEAR_CELL');
    },
    async clear({ commit, rootState, state }) {
      const clearInput = [];
      const clearStart = null;
      const name = await rootState.game.game.name;
      await commit('SET_NAME', name);
      await commit('SET_INPUT', clearInput);
      await commit('SET_START', clearStart);
      await commit('CLEAR_ANSWER', []);
      await commit('CLEAR_TIMER');
      await commit('STORE_SESSION');
      await commit('SET_TIMEFORMAT', state.session.timer);
      await commit('PAUSE_TIMER');
    },
    async fetchTimer({ commit, state }) {
      await commit('SET_TIMER');
      await commit('SET_TIMEFORMAT', state.session.timer);
    },
    async clearTimer({ commit }) {
      await commit('CLEAR_TIMER');
    },
    async setAnswer({ commit }, answer) {
      commit('SET_ANSWER', answer);
    },
    async removeAnswer({ commit }, index) {
      commit('REMOVE_ANSWER', index);
    },
    async startTimer({ commit, state }) {
      const localData = JSON.parse(localStorage.getItem(state.name));
      let startDate = Date.now();
      if (localData.timer) {
        startDate = Date.now() - localData.timer;
        await commit('SET_START', startDate);
        await commit('STORE_SESSION');
      } else {
        startDate = Date.now();
      }
      await commit('SET_START', startDate);
      await commit('STORE_SESSION');
      await commit('START_TIMER');
    },
    async pauseTimer({ commit }) {
      commit('PAUSE_TIMER');
    },
  },
  // Getters
  getters: {
    getUserAnswer: (state) => (index) => {
      const answer = _.find(state.session.input, { id: index });
      return answer;
    },
  },
};

export default session;
